<template>
<div v-if="!!model && !!ofirst" class="container">
    <NavBottom />
    <div class="itm-outer-wrp">
        <div class="itm-wrp">
            <div class="itm-tit">订单信息</div>
            <div class="infos-wrap">
                <template v-if="ofirst.Ticket_Name.split('_').length>1">
                    <div class="infos-inner-wrp">
                        <span>姓名: {{ofirst.Ticket_Name.split("_")[0]}}</span>
                    </div>
                    <div class="infos-inner-wrp">
                        <span>年级: {{ofirst.Ticket_Name.split("_")[1]}}</span>
                    </div>
                    <div class="infos-inner-wrp">
                        <span>家长: {{ofirst.Ticket_Name.split("_")[2]}}</span>
                    </div>
                    <div class="infos-inner-wrp">
                        <span>单位/组织: {{ofirst.Ticket_Name.split("_")[3]}}</span>
                    </div>
                </template>
                <template v-else>
                    <div class="infos-inner-wrp">
                        <span>姓名: {{ofirst.Ticket_Name}}</span>
                    </div>
                </template>
                <div class="infos-inner-wrp">
                    <span>电话: {{ofirst.Ticket_Phone}}</span>
                </div>
                <div class="infos-inner-wrp">
                    <span>票价: {{ofirst.OD_SellPrice}}</span>
                </div>
                <div class="infos-inner-wrp">
                    <span>状态: {{statusNow(Number(model.Orders_Status))}}</span>
                </div>
            </div>
            <!-- <div class="line-wrp date-time">

有效期: {{ofirst.Product_FlashStart + " —— " + ofirst.Product_FlashEnd}}

</div> -->
            <div class="ticket-tip">
                <label>票券说明: </label>{{model.Orders_Remark}}
            </div>
        </div>
        <img class="front-face" src="../assets/frotFace.png" alt="" srcset="">

    </div>
    <div v-for="(item,index) in list" :key="index">
        <div class="item-tit" :class="{'on':index == showIndx}" @click="showIndx = index">
            票据 - {{item.OD_Name}}
        </div>
        <div class="item-content" :class="{'on':index == showIndx}">
            <h3 class="ticket-tit">
                <span class="ticket-tit-text">
                {{item.OD_Name.replace(/^【.*】/g,"")}}
                </span>
            </h3>
            <div class="ticket-item-wrp">
                <div class="atcive-info">
                    <div class="act-tit">
                        <label>活动主题: </label> {{item.OD_Name}}
                    </div>
                    <div class="time-date">
                        <label>下单时间: </label> {{model.Orders_Created}}
                    </div>
                    <div class="adrress-wrp">
                        <div class="adres">
                            <label>活动地址: </label> {{ item.Product_ContactAddress}}
                        </div>
                        <a class="poi" :href="'https://apis.map.qq.com/uri/v1/marker?marker=coord:'+ (item.Stock_Latitude || item.Product_Latitude) +','+(item.Stock_Longitude || item.Product_Longitude) +';title:'+ item.OD_Name+';addr:'+item.Product_ContactAddress  +';&referer=DYKBZ-BT6W3-V5W3X-YVURI-73257-7QBF5'">查看地图</a>
                    </div>
                    <div class="act-tit">
                        <div class="etype"> 活动规格: {{item.Stock_Color}} {{item.Stock_Variety}} {{item.Stock_Size}}</div>
                    </div>

                </div>
                <div class="eCord">
                    <h3>电子票二维码</h3>
                    <img class="ecordImg" :src="domin+item.Ticket_Image" />
                    <div class="enum"> 票号: {{item.Ticket_Code}} </div>
                </div>
            </div>

            <div class="qunimg-wrp" v-if="item.Product_GroupImage.length>0">

                <div class="groupImage">
                    <img :src="domin+item.Product_GroupImage" />                    
                    <div class="groupImageText">报名后请扫码加群</div>
                </div>
            </div>
        </div>
    </div>
    <div class="itm-wrp tips-wrp">
        <div class="tip-tit liner-tit">温馨提示:</div>
        <div class="tipCont">
            1: 请不要把电子票随意分享给他人, 以免被盗用
        </div>
        <div class="tipCont">
            2: 入场时, 请提前打开电子票, 调亮手机屏幕, 扫描二维码检票入场, 1人1票
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            domin: this.domin,
            ofirst: "",
            list: [],
            showIndx: 0,
            Longitude: 0,
            Latitude: 0,
            model: {},
            id: 0
        };
    },
    created() {
        this.id = this.$route.params.id;
    },
    mounted() {
        this.init();
    },
    methods: {
        //0：未提交，1：待支付，2：待发货，3：待收货，4：待评价，5：待分享，6：交易完成，7：交易关闭，8：退款中，9：退款成功，10：退款拒绝
        statusNow(num) {
            switch (num) {
                case 0:
                    return "未提交";
                    break;
                case 1:
                    return "待支付";
                    break;
                case 2:
                    return "待发货";
                    break;
                case 3:
                    return "待收货";
                    break;
                case 4:
                    return "待评价";
                    break;
                case 5:
                    return "待分享";
                    break;
                case 6:
                    return "交易完成";
                    break;
                case 7:
                    return "交易关闭";
                    break;
                case 8:
                    return "退款中";
                    break;
                case 9:
                    return "退款成功";
                    break;
                case 10:
                    return "退款拒绝";
                    break;
            }
        },
        init() {
            this.wpost({
                url: "/Ajax/WordWelfare/getordersdetail",
                data: {
                    id: this.id
                },
                succ: res => {
                    this.ofirst = res.list[0];
                    this.list = res.list;
                    this.model = res.model;
                    console.log("res", res);
                }
            });
        }
    }
};
</script>

<style scoped>
.container {
    background-color: #c4d7ef;
    padding: 1px 0;
}

.atcive-info {
    font-size: 12px;
}

.qunimg-wrp {
    background: url("../assets/erwmBg.png") no-repeat center center / 100% auto;
}

.tips-wrp {
    padding: 12px;
}

.item-tit {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2;
    padding-left: 12px;
    margin: 2px;
    color: #fff;
    border-radius: 8px;
    background: linear-gradient(to right, #4692f0, #6566ff)
}

.item-tit::after {
    content: "";
    height: 5px;
    width: 5px;
    position: absolute;
    top: 13.5px;
    right: 12px;
    border: 1px solid transparent;
    border-right-color: #f0f0f0;
    border-bottom-color: #f0f0f0;
    transform: rotateZ(-45deg);
    transform-origin: center center;
    transition: transform 300ms ease;
}

.item-tit.on::after {
    transform: rotateZ(45deg);
}

.ticket-item-wrp {
    margin: 12px;
    background: #fff;
    margin-top: 22px;
    padding: 12px;
    padding-top: 40px;
    border-radius: 8px;
}
.ticket-tit-text{
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 2px;
}
.ticket-tit{
    text-align: center;
    position: absolute;
    min-width: 30%;
    max-width: 80%;
    left: 50%;
    top: 0;
    margin: 0;
    padding:6px 12px;
    color: #fff;
    transform: translate(-50%,-20%);
    border-radius: 0 0 10px 10px;
    background: linear-gradient(to right,#4692f0,#6566ff);   
}
.ticket-tit::before,.ticket-tit::after{
    content: "";
    height: 8px;
    width: 8px;
    background-color: #f39800;
    position: absolute;
    top: 0;
}
.ticket-tit::before{
    left: -7px;
    background:linear-gradient(-45deg,#4692f0 50%,#4692f0 50.1%,transparent 50.11%,transparent)

}
.ticket-tit::after{
    right: -7px;
    background:linear-gradient(45deg,#6566ff 50%,#6566ff 50.1%,transparent 50.11%,transparent)
}
.item-content {
    position: relative;
    display: none;
}

.item-content.on {
    display: block;
}

.qunimg-wrp {
    text-align: center;

}

.groupImage {
    padding:8px 12px;
    display: inline-block;
    background: linear-gradient(to bottom,#4692f0,#6566ff);
}

.groupImage img {
    width: 43vw;
}

.groupImageText {
    padding:6px;
    letter-spacing: 3px;
    color: #fff;
}

.tip-tit {
    font-size: 15px;
    margin: 8px 0;
}

.tipCont {
    font-size: 13px;
    line-height: 2;
    color: #6a6a6a;
}

.ticket-tip {
    padding: 0 12px;
    padding-bottom: 50px;
    font-size: 13px;
}

.line-wrp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 2;
    font-size: 14px;
}

.line-wrp text {
    display: block;
    width: 50%;
}

.eCord {
    padding: 12px;
    margin: 22px auto;
    text-align: center;
}

.ecordImg {
    width: 43vw;
}

.date-time {
    font-size: 16px;
    color: #f39800;
}

.enum {
    line-height: 2;
    font-size: 12px;
    color: #6a6a6a;
}

.etype {
    line-height: 1.6;
    font-size: 12px;
    color: #6a6a6a;
    text-align: left;
}

.time-date,
.act-tit,
.adres,
.poi {
    padding: 3px 0;
    font-size: 12px;
    color: #6a6a6a;
}

.poi {
    color: transparent;
    background: linear-gradient(to bottom, #5a77f8, #4041ec);
    background-clip: text;
    font-size: 13px;
    font-weight: bold;
    border-bottom: 1px solid #4041ec;
}

.liner-tit {
    font-weight: bold;
    background: linear-gradient(to bottom, #5a77f8, #4041ec);
    color: transparent;
    background-clip: text;
}

.adres {
    width: calc(100% - 66px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.adrress-wrp {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tit {
    color: #f39800;
    font-size: 17px;
    font-weight: bold;
}

.itm-outer-wrp {
    position: relative;
    overflow: hidden;
    margin: 8px;
    margin-top: 12px;
    padding: 6px;
    border-radius: 6px;
    background: url("../assets/tickBg.png") no-repeat center bottom / 100% 77%
}

.front-face {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 3;
}

.itm-wrp {
    position: relative;
    margin: 12px;
    border-radius: 6px;
    box-shadow: 0 0 6px #dcdcdc;
    background-color: #fff;
}

.itm-tit {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    padding: 12px 0;
    border-bottom: 1px solid #ededed;
    color: transparent;
    background: linear-gradient(to bottom, #5a77f8, #4041ec);
    background-clip: text;
}

.infos-wrap {
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.infos-inner-wrp {
    padding: 6px 0;
    width: 50%;
    line-height: 1.3;
    font-size: 14px;
}
</style>
